// JsFromRoutes CacheKey 3320e7d544bcb91564eb08af90ab35b1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/auth/login'),
  login: /* #__PURE__ */ definePathHelper('get', '/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/login'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/auth/logout'),
}
