// JsFromRoutes CacheKey a4736732c008eeae7388e26aedb618f4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  cancel: /* #__PURE__ */ definePathHelper('get', '/auth/sign_up/cancel'),
  new: /* #__PURE__ */ definePathHelper('get', '/auth/sign_up/new'),
  signUp: /* #__PURE__ */ definePathHelper('get', '/sign_up'),
  edit: /* #__PURE__ */ definePathHelper('get', '/auth/sign_up/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/auth/sign_up'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/auth/sign_up'),
  create: /* #__PURE__ */ definePathHelper('post', '/auth/sign_up'),
}
